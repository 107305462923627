<template>
  <StepLayout class="step4" over-on-mobile :title="$t('PAYMENT_DETAILS')">
    <template #leftColumn>
      <BaseInput v-model="userInfo.bank.bankName" class="fs-12" :label="$t('BANK.BANK_NAME')" />
      <BaseInput v-model="userInfo.bank.accountName" class="fs-12" :label="$t('BANK.ACCOUNT_NAME')" />
      <BaseInput v-model="userInfo.bank.accountNumber" class="fs-12" :label="$t('BANK.ACCOUNT_NUMBER')" />
      <BaseInput v-model="userInfo.bank.iban" mandatory class="fs-12" :label="$t('BANK.IBAN')" />
      <div class="step4__alert">
        <BaseIcon class="mr-10" icon="alert-circle" :custom_style="{ height: '13px', 'min-height': '18px', 'min-width': '18px', width: '13px' }" />
        <p>
          {{ $t('BANK.IBAN_ALERT') }}
        </p>
      </div>
    </template>
    <template #rightColumn>
      <BaseInput v-model="userInfo.bank.branch" class="fs-12" :label="$t('BANK.BRANCH')" />
      <BaseSelectWithFilter v-model="userInfo.bank.country" :label="$t('BANK.COUNTRY')" useInput :options="countryOptions" />
      <BaseInput v-model="userInfo.bank.swift" class="fs-12" :label="$t('BANK.SWIFT')" />
      <BaseInput v-model="userInfo.bank.bankCode" class="fs-12" :label="$t('BANK.BANK_CODE')" />
    </template>
  </StepLayout>
</template>

<script>
// Component
import BaseIcon from '@base/BaseIcon.vue';
import BaseInput from '@base/BaseInput.vue';
import BaseSelectWithFilter from '@base/BaseSelectWithFilter.vue';
import StepLayout from '@/components/registration/StepLayout.vue';

// Mixins
import helpers from '@utils/mixins/registration';

// Utils
import countriesHelper from '@utils/countries/countries';

import { required } from 'vuelidate/lib/validators';

export default {
  name: 'Step4',
  mixins: [helpers],
  components: {
    BaseIcon,
    BaseInput,
    BaseSelectWithFilter,
    StepLayout
  },
  props: {
    /**
     * The user that is being created
     */
    user: { default: () => ({}), type: Object }
  },
  data() {
    return {
      countryOptions: countriesHelper.getGroupedContries(),
      userInfo: {
        ...this.user
      }
    };
  },
  validations: {
    userInfo: {
      bank: {
        iban: {
          required
        }
      }
    }
  },
  watch: {
    userInfo: {
      deep: true,
      immediate: true,
      handler: function () {
        this.checkValidation();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.step4 {
  &__alert {
    display: flex;

    > p {
      @include inter-font($size: 11px, $color: var(--secondary-text-color));
      margin: 0;
      text-align: left;
      @include responsive($max: md) {
        font-size: calculateRem(10px);
        line-height: 12px;
      }
    }
  }
}
</style>
