<template>
  <StepLayout :title="$t('COMPLETE_PROFILE')">
    <template #leftColumn>
      <BaseCalendarInput
        v-model="$v.userInfo.additionalInformations.birthdate.$model"
        use-age-filter
        mandatory
        :error="$v.userInfo.additionalInformations.birthdate.$error"
        :label="$t('PROFILE.DATE_OF_BIRTH')"
      />
      <BaseSelect
        v-model="$v.userInfo.additionalInformations.english_level.$model"
        mandatory
        :error="$v.userInfo.additionalInformations.english_level.$error"
        :label="$t('PROFILE.ENGLISH_LEVEL')"
        :options="languageLevelOptions"
      />
      <BaseSelect
        v-model="$v.userInfo.additionalInformations.arabic_level.$model"
        mandatory
        :error="$v.userInfo.additionalInformations.arabic_level.$error"
        :label="$t('PROFILE.ARABIC_LEVEL')"
        :options="languageLevelOptions"
      />
      <BaseInput
        v-model="$v.userInfo.additionalInformations.details.portfolio_link.$model"
        mandatory
        :error="$v.userInfo.additionalInformations.details.portfolio_link.$error"
        :label="$t('PROFILE.PORTFOLIO_LINK')"
      />
    </template>
  </StepLayout>
</template>

<script>
// Vuelidate
import { required } from 'vuelidate/lib/validators';

// Base Components
import BaseCalendarInput from '@base/BaseCalendarInput.vue';
import BaseInput from '@base/BaseInput.vue';
import BaseSelect from '@base/BaseSelect.vue';

// Components
import StepLayout from '@/components/registration/StepLayout.vue';

// Mixins
import helpers from '@utils/mixins/registration';

export default {
  name: 'Step2',
  mixins: [helpers],
  components: {
    BaseCalendarInput,
    BaseInput,
    BaseSelect,
    StepLayout
  },
  props: {
    /**
     * The user that is being created
     */
    user: { default: () => ({}), type: Object }
  },
  data() {
    return {
      languageLevelOptions: [
        this.$t('LANGUAGE_LEVEL.NATIVE'),
        this.$t('LANGUAGE_LEVEL.FLUENT'),
        this.$t('LANGUAGE_LEVEL.INTERMEDIATE'),
        this.$t('LANGUAGE_LEVEL.BEGINNER'),
        this.$t('LANGUAGE_LEVEL.NONE')
      ],
      userInfo: {
        ...this.user
      },
      wordsLimit: 150
    };
  },
  validations: {
    userInfo: {
      additionalInformations: {
        arabic_level: {
          required
        },
        birthdate: {
          required
        },
        english_level: {
          required
        },
        details: {
          portfolio_link: {
            required,
            valid: value => {
              const linkRegex = new RegExp(
                '^(https?:\\/\\/)?' + // protocol
                  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                  '(\\#[-a-z\\d_]*)?$',
                'i'
              ); // fragment locator
              return !!linkRegex.test(value);
            }
          }
        }
      }
    }
  },
  watch: {
    userInfo: {
      deep: true,
      immediate: true,
      handler: function () {
        this.checkValidation();
      }
    }
  }
};
</script>
<style lang="scss">
.q-textarea.q-field--dense .q-field__control {
  padding: 5px 5px 5px 12px;
}
@include responsive($max: mobileL) {
  .q-textarea .q-field__control {
    height: 145px;
  }
}
</style>
