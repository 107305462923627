<template>
  <section class="equipmentCategoryItems">
    <div class="equipmentCategoryItems__header">
      <p>{{ category | firstUppercase }}</p>
      <BaseIcon icon="close" @click.native="$emit('close')" />
    </div>

    <BaseInput icon="search" :label="$t('SEARCH')" :value="filterText" @update:value="filterText = $event" />
    <div class="equipmentCategoryItems__items">
      <div v-for="(item, index) of items" :key="`${index}__brand`" class="equipmentCategoryItems__brands">
        <p class="equipmentCategoryItems__brand">{{ item.brand | firstUppercase }}</p>
        <div class="equipmentCategoryItems__models">
          <BaseCheckbox v-for="(model, index) of item.models" :key="`${index}__model`" :val="model" :value="tmpSelectedItems" @update:value="handleUpdateItems">
            {{ model }}
          </BaseCheckbox>
        </div>
      </div>
    </div>
    <div class="equipmentCategoryItems__confirm">
      <BaseButton label="Confirm" :custom_style="{ 'background-color': '#FECB30', margin: 'auto', width: '90%' }" @click.native="handleConfirm" />
    </div>
  </section>
</template>

<script>
// Base Components
import BaseButton from '@base/BaseButton.vue';
import BaseCheckbox from '@base/BaseCheckbox.vue';
import BaseIcon from '@base/BaseIcon.vue';
import BaseInput from '@base/BaseInput.vue';

// Utils
import equipUtils from '@utils/equipments';

// Mixins
import checkBreakpoint from '@utils/mixins/check-breakpoint.js';

export default {
  name: 'EquipmentCategoryItems',
  mixins: [checkBreakpoint],
  components: {
    BaseButton,
    BaseCheckbox,
    BaseIcon,
    BaseInput
  },
  props: {
    /**
     * Category
     */
    category: { default: '', type: String },
    /**
     * Selected items
     */
    selectedItems: { default: () => [], type: Array }
  },
  data() {
    return {
      filterText: '',
      tmpSelectedItems: []
    };
  },
  computed: {
    /**
     * Retrieving all the items related to the selected category
     */
    items() {
      const allCategoryItems = equipUtils.retrieveItems({ category: this.category }) || [];
      const filteredCategoryItems = this.searchItem({ items: allCategoryItems });
      return filteredCategoryItems;
    },
    /**
     * Retrieving items mapped
     */
    mappedItems() {
      return equipUtils.mapItems({ items: this.items }) || [];
    }
  },
  watch: {
    selectedItems: {
      immediate: true,
      handler: function () {
        this.tmpSelectedItems = this.selectedItems;
      }
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('updated:items', this.tmpSelectedItems);
      this.$emit('close');
    },
    handleUpdateItems($event) {
      if (this.checkBreakpoint({ lowerBoundary: 455 })) {
        this.$emit('updated:items', $event);
        return;
      }
      this.tmpSelectedItems = $event;
    },
    /**
     * Searching items from the list
     */
    searchItem({ items }) {
      /**
       * For each category item, keep the brand and retrieve the models only if they contains the string
       * from the search bar
       */
      return items.map(({ brand, models }) => ({
        brand,
        models: models.filter(model => model.toLowerCase().indexOf(this.filterText.toLowerCase()) !== -1)
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
.equipmentCategoryItems {
  $padding: 20px;

  background-color: var(--main-bg-color);
  border-radius: 10px;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  padding: $padding;

  &__confirm {
    background-color: var(--secondary-bg-color);
    display: none;
    height: 84px;
    margin-bottom: -$padding;
    margin-left: -$padding;
    width: 100vw;
  }

  &__brand {
    color: var(--main-text-color);
    font-family: $inter-regular;
    font-size: calculateRem(14px);
    font-weight: bold;
    text-align: left;
  }

  &__brands {
    & ~ .equipmentCategoryItems__brands {
      margin-top: 20px;
    }
  }

  &__header {
    display: none;
    margin-bottom: 32px;

    > p {
      color: var(--main-text-color);
      font-family: $inter-regular;
      font-size: calculateRem(16px);
      font-weight: bold;
      margin: 0;
    }
  }

  &__items {
    height: 100%;
    min-height: 0;
    overflow-y: auto;
  }

  &__models {
    display: grid;
    grid-template-rows: auto;
    row-gap: 20px;
  }
}

@include responsive($max: mobileL) {
  .equipmentCategoryItems {
    align-content: space-between;
    display: flex;
    flex-direction: column;
    grid-template-columns: 100%;

    &__confirm {
      display: flex;
      padding: 20px;
    }

    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
