<template>
  <section class="equipmentCategory">
    <div :class="['equipmentCategory__header', { 'equipmentCategory__header--is-focused ': category.toLowerCase() === itemSelected.toLowerCase() }]">
      <span>{{ category }}</span>
      <BaseIcon icon="angular-arrow" />
    </div>
    <div v-if="items.length > 0" class="equipmentCategory__items">
      <q-chip v-for="(item, index) in items" :key="index" class="equipmentCategory__item">
        <div class="equipmentCategory__item-content">
          <span>{{ item }}</span>
          <BaseIcon icon="close" :custom_style="{ cursor: 'pointer', 'margin-top': '2px', width: '11px' }" @click.native="$emit('remove:item', item)" />
        </div>
      </q-chip>
    </div>
    <hr class="equipmentCategory__hr" />
  </section>
</template>

<script>
// Base Components
import BaseIcon from '@base/BaseIcon.vue';

/**
 * Component used to show an equipment category and its selected items
 *
 * @displayName EquipmentCategory
 */
export default {
  name: 'EquipmentCategory',
  components: {
    BaseIcon
  },
  props: {
    /*
     * used to set the category selected
     */
    itemSelected: { default: '', type: String },
    /**
     * Category name
     */
    category: { default: '', type: String },
    /**
     * The list of selected items of the category
     */
    items: { default: () => [], type: Array }
  }
};
</script>

<style lang="scss" scoped>
.equipmentCategory {
  max-width: 315px;
  margin-right: 10px;

  &__hr {
    background-color: var(--grey-hr);
    border: unset;
    height: 1px;
    width: 100%;
  }

  &__header {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 42px;
    justify-content: space-between;
    padding: 15px;

    &--is-focused {
      background-color: var(--main-bg-color);
      border-radius: 10px;
      font-weight: bold;
    }
  }

  &__item {
    background-color: var(--main-bg-color);
    border-radius: 10px;
  }

  &__item-content {
    align-items: center;
    color: var(--main-text-color);
    display: flex;
    font-family: $inter-regular;
    font-size: calculateRem(14px);
    justify-content: space-between;

    > span {
      margin-right: 9px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
