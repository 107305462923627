<template>
  <StepLayout :right-column-title="$t('ACCOUNT')" :left-column-title="$t('PERSONAL_DATA.PERSONAL_DATA')" :title="$t('CREATE_ACCOUNT')">
    <template #leftColumn>
      <BaseInput v-model="$v.userInfo.user.name.$model" class="step1__input" mandatory :error="$v.userInfo.user.name.$error" :label="$t('PERSONAL_DATA.FIRST_NAME')" />
      <BaseInput v-model="$v.userInfo.user.surname.$model" class="step1__input" mandatory :error="$v.userInfo.user.surname.$error" :label="$t('PERSONAL_DATA.LAST_NAME')" />
      <BaseInput
        v-model="$v.userInfo.user.email.$model"
        :validators="[handleEmailValidation]"
        class="step1__input"
        mandatory
        :error="$v.userInfo.user.email.$error"
        :label="$t('PERSONAL_DATA.EMAIL')"
      />
      <BasePhoneNumber v-model="$v.userInfo.user.phone.$model" mandatory />
      <!-- <BaseInput class="step1__input" :label="$t('PERSONAL_DATA.OTP_VERIFICATION')" /> -->
    </template>
    <template #rightColumn>
      <BaseLocation
        :style="{ 'background-color': '#ffffff', height: '40px', padding: '6px 12px', 'margin-bottom': '20px' }"
        mandatory
        type="(cities)"
        :value="$v.userInfo.additionalInformations.main_location.$model"
        :error="$v.userInfo.additionalInformations.main_location.$error"
        class="step1__input stepLocation"
        :placeholder="$t('LOCATION_PH_REGISTRATION')"
        @update:value="userInfo.additionalInformations.main_location = $event"
      />

      <BaseInput v-model="$v.userInfo.password.$model" mandatory type="password" :error="$v.userInfo.password.$error" :label="$t('PASSWORD')" :error-message="$t('PASSWORD_REQ')" />

      <BaseInput
        v-model="$v.userInfo.confirmPassword.$model"
        mandatory
        type="password"
        :error="$v.userInfo.confirmPassword.$error"
        :label="$t('PASSWORD_RECONFIRM')"
        :error-message="$t('DIFFERENT_PASSWORD')"
      />

      <div class="registration__checkbox">
        <BaseCheckbox
          :colors="{ bg: '#fff0', 'bg-active': '#000' }"
          v-model="$v.termsAndConditionAccepted.$model"
          type="checkbox"
          :error="$v.termsAndConditionAccepted.$error"
          :errorMessage="$t('ACCEPT_TERMS_&_CONDITION')"
        >
          <template #label>
            <label class="registration__conditions" for="checkbox">
              {{ $t('ACCEPT') }}
              <a href="https://www.flashy.ae/terms-and-conditions" target="_blank" @click.stop> {{ $t('TERMS_AND_CONDITION') }}*</a>
            </label>
          </template>
        </BaseCheckbox>
      </div>
    </template>
  </StepLayout>
</template>

<script>
// Base Components
import BaseInput from '@base/BaseInput.vue';
import BaseLocation from '@base/BaseLocation.vue';
import BasePhoneNumber from '@base/BasePhoneNumber.vue';

// Components
import StepLayout from '@/components/registration/StepLayout.vue';

import { email, required, sameAs } from 'vuelidate/lib/validators';

// Mixins
import helpers from '@utils/mixins/registration';
import emailValidation from '@utils/mixins/email-validation-mixin';

//checkbox
import BaseCheckbox from '@base/BaseCheckbox.vue';

export default {
  name: 'Step1',
  mixins: [helpers, emailValidation],
  components: {
    BaseCheckbox,
    BaseInput,
    BaseLocation,
    BasePhoneNumber,
    StepLayout
  },
  props: {
    /**
     * The user that is being created
     */
    user: { default: () => ({}), type: Object }
  },
  data() {
    return {
      userInfo: {
        ...this.user,
        confirmPassword: ''
      },
      termsAndConditionAccepted: false
    };
  },
  validations: {
    userInfo: {
      password: {
        required,
        valid: value => {
          const pwdRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
          return pwdRegex.test(value);
        }
      },
      confirmPassword: { required, sameAsPassword: sameAs('password') },
      additionalInformations: {
        main_location: {
          required
        }
      },
      user: {
        email: {
          email,
          required
        },
        name: {
          required
        },
        surname: {
          required
        },
        phone: {
          required,
          valid: value => {
            const numberRegex = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/);
            return numberRegex.test(value.replace(/\s/g, ''));
          }
        }
      }
    },
    termsAndConditionAccepted: {
      checked: val => {
        return val;
      }
    }
  },
  watch: {
    userInfo: {
      deep: true,
      immediate: true,
      handler: function () {
        this.checkValidation();
      }
    },
    termsAndConditionAccepted() {
      this.checkValidation();
    }
  }
};
</script>
<style lang="scss">
.registration {
  &__checkbox {
    text-align: left;
  }
  &__conditions {
    align-self: center;
    color: var(--box-bg-color);
    font-family: $inter-regular;
    font-size: 10px;
    a {
      color: var(--box-bg-color);
    }
  }
  &__activeMargin {
    margin-top: 25px;
  }
  .q-field__bottom {
    padding-bottom: 8px;
    position: relative;
    transform: none;
    @include responsive($max: md) {
      min-height: 10px;
      padding: 3px;
    }
  }
  .q-field--with-bottom {
    padding-bottom: 0;
  }
  .stepLocation {
    @include responsive($max: md) {
      margin-bottom: 10px !important;
    }
  }
}
</style>
