<template>
  <StepLayout class="step3" over-on-mobile :title="$t('YOUR_EQUIPMENT')">
    <template #leftColumn>
      <EquipmentCategory
        v-for="(equipment, index) of equipments"
        :key="`${index}_category`"
        :category="equipment.label"
        :items="getItems(equipment.value)"
        @click.native="selectedEquipment = equipment.value"
        @remove:item="removeItem"
        :itemSelected="selectedEquipment"
      />
      <div class="step3__ownstudio">
        <span class="text-left">{{ $t('PHOTO_STUDIO_ACCESS') }}</span>
        <div :class="['step3__ownstudio-option', { 'step3__ownstudio-option--is-selected': userInfo.additionalInformations.details.ownStudio === 'no' }]" @click="setOwnStudio('no')">No</div>
        <div :class="['step3__ownstudio-option', { 'step3__ownstudio-option--is-selected': userInfo.additionalInformations.details.ownStudio === 'yes' }]" @click="setOwnStudio('yes')">Yes</div>
      </div>
    </template>
    <template v-if="selectedEquipment" #column-overlay>
      <EquipmentCategoryItems :key="selectedEquipment" :category="selectedEquipment" @updated:items="updateItems" :selected-items="getItems(selectedEquipment)" @close="selectedEquipment = false" />
    </template>
  </StepLayout>
</template>

<script>
// Component
import EquipmentCategory from '@/components/equipment/EquipmentCategory.vue';
import EquipmentCategoryItems from '@/components/equipment/EquipmentCategoryItems.vue';
import StepLayout from '@/components/registration/StepLayout.vue';

// Utils
import equipUtils from '@utils/equipments';

// Mixins
import helpers from '@utils/mixins/registration';

export default {
  name: 'Step3',
  mixins: [helpers],
  components: {
    EquipmentCategory,
    EquipmentCategoryItems,
    StepLayout
  },
  props: {
    /**
     * The user that is being created
     */
    user: { default: () => ({}), type: Object }
  },
  data() {
    return {
      selectedEquipment: '',
      selectedEquipmentItems: {},
      userInfo: {
        ...this.user
      }
    };
  },
  validations: {
    userInfo: {}
  },
  computed: {
    equipments() {
      return equipUtils.retrieveEquipmentsCategories();
    }
  },
  watch: {
    selectedEquipmentItems: {
      deep: true,
      handler: function () {
        this.$set(this.userInfo.additionalInformations.details, 'equipment', this.selectedEquipmentItems);
      }
    },
    userInfo: {
      deep: true,
      immediate: true,
      handler: function () {
        this.checkValidation();
      }
    }
  },
  created() {
    this.selectedEquipmentItems = this.userInfo.additionalInformations.details.equipment || {};
  },
  methods: {
    getItems(equipmentValue) {
      return this.selectedEquipmentItems[equipmentValue];
    },
    removeItem(item) {
      let items = this.selectedEquipmentItems[this.selectedEquipment];
      items = items.filter(_item => _item !== item);

      this.$set(this.selectedEquipmentItems, this.selectedEquipment, items);
    },
    setOwnStudio(value) {
      this.$set(this.userInfo.additionalInformations.details, 'ownStudio', value);
    },
    updateItems($event) {
      this.$set(this.selectedEquipmentItems, this.selectedEquipment, $event);
    }
  }
};
</script>

<style lang="scss">
.step3 {
  &__equipiments {
    display: flex;
    flex-direction: column;
  }

  &__ownstudio {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 10px;

    > span {
      margin-right: auto;
    }
  }

  &__ownstudio-option {
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 35px;
    @include inter-font($size: 12px, $color: var(--main-text-color));
    justify-content: center;
    min-width: 40px;
    text-transform: uppercase;

    &--is-selected {
      background-color: var(--main-text-color);
      color: var(--terziary-text-color);
    }
  }

  .stepLayout__grid {
    > div[data-type='column'] {
      overflow-y: auto;
    }
  }
}

@include responsive($max: mobileL) {
  .step3 {
    &__equipiments {
      display: flex;
      flex-direction: column;
    }

    &__ownstudio {
      align-items: center;
      display: flex;
      justify-content: center;

      > span {
        margin-right: auto;
      }
    }

    &__ownstudio-option {
      align-items: center;
      border-radius: 10px;
      color: var(--main-text-color);
      cursor: pointer;
      display: flex;
      font-family: $inter-regular;
      font-size: calculateRem(12px);
      height: 35px;
      justify-content: center;
      min-width: 40px;

      &--is-selected {
        background-color: var(--main-text-color);
        color: var(--terziary-text-color);
      }
    }
  }
}
</style>
