<template>
  <Registration :large="getStep === 3">
    <template #wizard>
      <BaseStepper handle-navigation :completed-label="$t('COMPLETE_REGISTRATION')" :steps="steps" @complete:stepper="completeRegistration" @next-step="goToNextStep" @prev-step="goToPrevStep">
        <template #CREATE_ACCOUNT>
          <Step1 :user="{ ...user }" />
        </template>
        <template #INSTAGRAM_LINK>
          <Step2 :user="{ ...user }" />
        </template>
        <template #EQUIPMENT>
          <Step3 :user="{ ...user }" />
        </template>
        <template #PAYMENT>
          <Step4 :user="{ ...user }" />
        </template>
      </BaseStepper>
    </template>
  </Registration>
</template>

<script>
// Store
import { mapGetters, mapActions } from 'vuex';

// Base Components
import BaseStepper from '@base/BaseStepper.vue';

// Components
import Registration from './Registration.vue';
import Step1 from './photographer/Step1.vue';
import Step2 from './photographer/Step2.vue';
import Step3 from './photographer/Step3.vue';
import Step4 from './photographer/Step4.vue';

// Api
import { UserApi } from '@api/index';

export default {
  name: 'PhotographerRegistration',
  components: {
    BaseStepper,
    Registration,
    Step1,
    Step2,
    Step3,
    Step4
  },
  data() {
    return {
      steps: [
        {
          name: 'CREATE_ACCOUNT'
        },
        {
          name: 'INSTAGRAM_LINK'
        },
        {
          name: 'EQUIPMENT'
        },
        {
          name: 'PAYMENT'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getStep: 'stepper/getStep',
      user: 'user/getWizardUser'
    })
  },
  methods: {
    ...mapActions({
      goToNextStep: 'stepper/goToNextStep',
      goToPrevStep: 'stepper/goToPrevStep',
      setRegistrationCompleted: 'user/setRegistrationCompleted',
      updateUser: 'user/updateWizardUser'
    }),
    async completeRegistration() {
      const newUser = await UserApi.createPhotographer(this.user);
      if (newUser.statusCode === 200) this.setRegistrationCompleted(true);
    }
  }
};
</script>
